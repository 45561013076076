<template>
  <div>
    <!-- Filtros -->
    <div>
       <v-card class="mb-5" >
            <custom-snackbar ref="snackbar" />
       </v-card>
      <v-toolbar flat class="d-flex flex-row-reverse" color="rgba(0,0,0,0)">
          <v-dialog
              v-model="dialog"
              transition="dialog-bottom-transition"
              max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      class="mr-3"
                      v-on="on"
                  >
                    <v-icon dark>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click="exportFile('PDF')">
                    <v-list-item-title>Exportar PDF</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="exportFile('Excel')">
                    <v-list-item-title>Exportar Excel</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-dialog>
        </v-toolbar>
    </div>
    <FiltroMobile :has-change="pendingSearch" @search="getData">
      <v-col class="col-12 col-md-3">
        <EmpresasSelect v-model="empresaFilterValue" @change="fieldsSelected"></EmpresasSelect>
      </v-col>

      <v-col class="col-12 col-md-3">
        <ProducoesSelect v-model="producoesFilterValue" :empresa="empresaFilterValue" mostra-todos-label multiple return-object :disabled="!empresaFilterValue" @input="fieldsSelected"></ProducoesSelect>
      </v-col>

      <v-col class="col-12 col-md-2">
        <TalhoesSelect v-model="talhoesFilterValue" :empresa="empresaFilterValue" :producao="producoesFilterValue" mostra-todos-label multiple @input="fieldsSelected"></TalhoesSelect>
      </v-col>
      <v-col class="col-12 col-md-2">
        <v-autocomplete
            v-model="variedadesFilterValue"
            :items="variedadesOptions"
            outlined
            dense
            item-text="nome"
            item-value="id"
            label="Variedade"
            @change="fieldsSelected"
        />
      </v-col>
    </FiltroMobile>
           <v-data-table
              dense
              sort-by="talhao"
              sort-desc="true"
              class="custom-table"
              show-group-by
              ref="refTalhoes"
              group-by="producao_sigla"
              disable-pagination
              :headers="headers"
              :items="data"
              :hide-default-footer="true"
            >
              <template v-slot:[`group.header`]="{ group, isOpen, toggle }">
                <td colspan="13" style="font-size: 10px;padding-right: 0;">
                  <v-btn text icon small @click="toggle" class="cursor-pointer">
                    <v-icon>
                      {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                    </v-icon>
                  </v-btn>
                  {{ group }}
                </td>
              </template>
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>
               <template v-slot:[`item.area`]="{ item }" class="text-right num_margin">
                <span class="d-flex flex-column">
                  {{ $stringFormat.formatNumber(item.area, 0, 2) }}
                </span>
              </template>

              <template v-slot:[`item.colhido`]="{ item }" class="text-right num_margin">
                <span class="text-right num_margin d-flex flex-column">
                  {{ $stringFormat.formatNumber(getColhido(item.colhido), 0, 2) }}
                </span>
              </template>
                <template v-slot:[`item.saldo`]="{ item }" class="text-right num_margin">
                <span class="text-right num_margin d-flex flex-column">
               {{ $stringFormat.formatNumber(getColhido(item.area - item.colhido) , 0, 2) }}
                </span>
              </template>

              <template v-slot:[`item.data_inicial`]="{ item }">
                {{ $stringFormat.dateISOToBRL(item.data_inicial, true) }}
              </template>

              <template v-slot:[`item.data_finalizacao`]="{ item }">
                <div
                  v-if="
                    item.data_finalizacao === null ||
                    item.data_finalizacao === '1969-12-31'
                  "
                >
                  Não finalizada
                </div>
                <div v-else>
                  {{ $stringFormat.dateISOToBRL(item.data_finalizacao, true) }}
                </div>
              </template>

              <template v-slot:[`item.situacao`]="{ item }">
              <custom-label
                :color="getColor(item.situacao)"
                :text="getSituacao(item.situacao)"
              />
            </template>

              <template v-slot:[`item.qualidade_tapete`]="{ item }">
                  {{ getResistencia(item.qualidade_tapete) }}
              </template>

              <template v-slot:[`item.data_qualidade`]="{ item }">
                  <span>{{ $stringFormat.dateISOToBRL(item.data_qualidade, true) }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip
                      v-model="show"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="visualizarHistoricoTalhao(item.id,item.producao_id,item.data_inicial,item.numero_ciclo)"
                        >
                           <v-icon small class="mr-2">
                            mdi-file-eye-outline
                          </v-icon>
                     </v-btn>
                    </template>
                    <span>Histórico do Talhão</span>
                  </v-tooltip>
              </template>

              <template v-slot:no-data >
                <div v-if="dataError">
                  <v-row class="justify-center mt-1">
                    <v-col class="col-lg-6">
                      <custom-alert :text="errorMessage"/>
                    </v-col>
                  </v-row>
                </div>

                <div v-else-if="loading">
                  <v-row style="margin: -16px">
                    <v-col class="col-lg-12 p-0">
                      <v-progress-linear absolute indeterminate color="green" />
                    </v-col>
                  </v-row>
                </div>

                <div v-else-if="empresaFilterValue == '' || producoesFilterValue == ''">
                <v-row class="justify-center my-1">
                  <v-col class="col-lg-6">
                    <h6>Selecione Empresa e Produção/Produções</h6>
                  </v-col>
                </v-row>
              </div>

                <div v-else>
                  <v-row class="justify-center my-1">
                    <v-col class="col-lg-6">
                      <h6>Nenhum talhão liberado para colheita encontrado</h6>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-data-table>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    status: false,
    valid: true,
    menu: false,
    menu2: false,
    menu3: false,
    pendingSearch: false,
    empresasOptions: [],
    talhaoSelected: false,
    dialog: false,
    dataError: false,
    errorMessage: 'Não foi possível carregar os talhões liberados para collheita',
    talhoesOptions: [],
    variedadesOptions: [{ nome: 'Todas', id: '' }],
    culturasOptions: [],
    empresasOptionsFilter: [],
    producoesOptionsFilter: [{ id: '', sigla: 'Todas' }],
    producoesOptions: [{ id: '', sigla: 'Necessário escolher uma empresa' }],
    areaTotalHa: '',
    producoesField: [],
    producoesFilterValue: [],
    talhoesFilterValue: '',
    variedadesFilterValue: '',
    empresaFilterValue: '',
    data: [],
    editedIndex: -1,
    defaultItem: {
      empresa_id: 0,
      producao_id: 0,
      cultura_id: 0,
      talhao_id: 0,
      variedade_cultura_id: 0,
      data_inicial: null,
      data_finalizacao: null,
      area_talhao: 0,
      area_liberada: 0,
      colhido: 0,
      oferta: 0,
      resistencia: 0,
      situacao: 1,
      empresa: {},
      producao: {},
      talhao: {},
      cultura: {},
      variedade: {},
    },
    options: {},
    loading: false,
  }),
  computed: {
    headers () {
      return [
        { text: 'Talhão', value: 'talhao', class: 'custom-table--header', groupable: false },
        { text: 'Variedade', value: 'variedade', class: 'custom-table--header', groupable: false },
        { text: 'Área', value: 'area', class: 'custom-table--header', groupable: false },
        { text: 'Colhido', value: 'colhido', class: 'custom-table--header', groupable: false },
        { text: 'Saldo de Grama', value: 'saldo', class: 'custom-table--header', groupable: false },
        { text: 'Situação', value: 'situacao', class: 'custom-table--header', groupable: false },
        { text: 'Liberado em', value: 'data_inicial', class: 'custom-table--header', groupable: false },
        { text: 'Finalizado em', value: 'data_finalizacao', class: 'custom-table--header', groupable: false },
        { text: 'Qual. Tapete', value: 'qualidade_tapete', class: 'custom-table--header', groupable: false },
        { text: 'Últ Qual. Tapete', value: 'data_qualidade', class: 'custom-table--header', groupable: false },
        { value: 'actions', class: 'custom-table--header', groupable: false, align: 'right p-0' },
      ]
    },
    indexedItems () {
      return this.data.map((item, index) => ({
        id: index,
        ...item,
      }))
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  mounted () {
    this.initialize()

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Talhões Liberados para Colheita' },
    ])
  },
  methods: {
    collapseAllGroups (tables) {
      tables.forEach((table) => {
        const refTable = this.$refs[table]
        const keys = Object.keys(refTable.$vnode.componentInstance.openCache)

        keys.forEach((x) => {
          refTable.$vnode.componentInstance.openCache[x] = false
        })
      })
    },
    initialize () {
      ApiService.get('/variedade-cultura').then((res) => {
        for (const variedade of res.data.data) {
          this.variedadesOptions.push(variedade)
        }
      })

      ApiService.get('/cultura').then((res) => {
        this.culturasOptions = res.data.data
      })
    },
    fieldsSelected () {
      this.pendingSearch = true
    },
    getColhido (colhido) {
      if (!colhido || colhido === 'NaN') {
        return 0
      }

      return colhido
    },
    async getData () {
      if (!this.empresaFilterValue || this.producoesFilterValue.length === 0) {
        this.errorMessage = 'Selecione Empresa e Produção'
        this.dataError = true
        this.data = []
        this.loading = false
        return
      }

      this.loading = true
      this.pendingSearch = false
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,
        qtde: itemsPerPage,
        sortBy,
        sortDesc,
        empresa_id: this.empresaFilterValue === '' ? 0 : this.empresaFilterValue,
        producao_id: this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v && v !== ''),
        talhao_id: this.talhoesFilterValue,
        variedade_id: this.variedadesFilterValue,
      }

      ApiService.query('talhoes-liberados', { params })
        .then((res) => {
          const dados = res.data
          this.loading = false
          this.totalItems = dados.total
          this.pageCount = dados.pageCount
          this.data = dados.data
          this.dataError = false

          if (this.data.length === 0) {
            this.dataError = true
            this.errorMessage = 'Nenhum registro encontrado'
          }

          this.$nextTick(() => {
            this.collapseAllGroups(['refTalhoes'])
          })
        })
        .catch((error) => {
          this.loading = false
          this.dataError = true

          const errorResponse = error.response.data

          if (errorResponse.errors) {
            this.errorMessage = errorResponse.errors
          }
        })
    },
    visualizarHistoricoTalhao (talhao, producao, dataInicial, liberacao) {
      const ano = new Date(dataInicial).getUTCFullYear()
      const empresa = this.empresaFilterValue
      const baseUrl = `${window.location.origin}/agricola/historico-talhao/`
      const params = `?empresa=${empresa}&producao=${producao}&talhao=${talhao}&ano=${ano}&liberacao=${liberacao}`
      window.open(`${baseUrl}${params}`, '_blank')
    },

    getColor (boolField) {
      switch (boolField) {
        case 0:
          return 'danger'
        case 1:
          return 'warning'
        case 2:
          return 'success'
      }
    },
    getSituacao (status) {
      switch (status) {
        case 0:
          return 'Cancelada'
        case 1:
          return 'Liberada'
        case 2:
          return 'Colhida'
      }
    },
    getResistencia (resistencia) {
      if (resistencia === 1) {
        return 'Boa'
      } else if (resistencia === 2) {
        return 'Regular'
      } else {
        return 'Fraca'
      }
    },

    async exportFile (tipo) {
      if (this.data.length > 0) {
        const url = tipo === 'Excel' ? '/relatorios/exportar' : '/relatorios/exportar-pdf'
        const filtros = {
          empresa_id:
            this.empresaFilterValue,
          producao_id:
           this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v && v !== ''),
          talhao_id:
            this.talhoesFilterValue,
          variedade_id:
            this.variedadesFilterValue,
        }

        const params = {
          relatorio: tipo === 'Excel' ? 'TalhoesLiberados' : 'talhoes-liberados/talhoes-liberados',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show(
          'Gerando o arquivo',
          'Aguarde alguns segundos para baixar o arquivo',
          'success',
          -1,
          true,
        )

        ApiService.post(url, params)
          .then((res) => {
            if (res.data.data.arquivo !== '') {
              this.$refs.snackbar.show(
                'Arquivo gerado com sucesso',
                '',
                'success',
              )

              window.open(res.data.data.arquivo, '_blank')
            } else {
              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível gerar o arquivo, tente novamente mais tarde.',
                'danger',
              )
            }
          })
          .catch((error) => {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível gerar o arquivo, tente novamente mais tarde.',
              'danger',
            )

            console.error('Erro: ', error)
          })
      } else {
        this.$refs.snackbar.show(
          'Erro',
          'Não foram encontrados dados para exportação!',
          'danger',
        )
      }
    },
  },
}
</script>
